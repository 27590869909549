<template>
    <base-layout>
        <template v-slot:sidebar>
            <app-sidebar-details title="Member details" @close-sidebar="closeSidebar">
                <member-details
                    :auth-spid="auth_spid"
                    :auth-uuid="auth_uuid"
                    :auth-id="auth_id"
                    :auth-provider="auth_provider"

                    @saved="getMembers"
                    @close-sidebar="closeSidebar"
                />
            </app-sidebar-details>
        </template>

        <div class="page-members">
            <app-loader v-if="loading" fixed-on="desktop" />

            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <app-search-box
                            v-model="filter.word"

                            label="Search"

                            :loading="loading"

                            :debounce="1000"

                            :show-clear-button="true"

                            @search="onSearch"
                        />
                    </div>

                    <div class="col-8 col-tab-12">
                        <app-table
                            class="members-table"

                            :sort-value="filter.sort"
                            :sort-options="sort"
                            @sort-change="onSortChange"

                            :clickable="true"
                            :selected-row="member_uuid"

                            :cols="cols"
                            :rows="members"

                            :loading="loading"

                            @row-click="onMembersRowClick"
                            @row-click-mobile="openMemberDetails"

                            @remove-member="onRemoveMember"

                            ref="members"
                        />

                        <app-pagination
                            v-if="pagination.total"

                            v-model="pagination.page"

                            :total="pagination.total"
                            :limit="pagination.limit"
                            :page-range="pagination.range"

                            @change="onPageChange"
                        />
                    </div>

                    <div class="col-4 col-tab-12">
                        <app-adding-banner
                            header="Add a member"
                            description="Add members to allow them to login to the portal"

                            image="member"

                            :route="{ name: 'add-member' }"
                        />
                    </div>
                </div>
            </div>

            <app-dialog-member-removal
                v-if="dialogs.member.remove.show"
                v-model="dialogs.member.remove.show"

                :uuid="dialogs.member.remove.uuid"
                :authid="dialogs.member.remove.authid"
                :email="dialogs.member.remove.email"

                :auth-role-groups="dialogs.member.remove.AuthRoleGroups"

                @confirm="onDialogMemberRemovalConfirm"
                @close="onDialogMemberRemovalClose"
            />
        </div>
    </base-layout>
</template>

<script>
import { mapGetters } from 'vuex'

import baseLayout from '@/views/layouts/base-layout'
import appSidebarDetails from '@/components/app-sidebar-details'

import memberDetails from '@/views/member'

import appLoader from '@/components/app-loader'
import appTable from '@/components/app-table'
import appPagination from '@/components/app-pagination'
import appAddingBanner from '@/components/app-adding-banner'
import appSearchBox from '@/components/app-search-box'

import appDialogMemberRemoval from './components/app-dialog-member-removal'

import errMessage from '@/helpers/errMessage'

import * as bodyScrollLock from 'body-scroll-lock'

export default {
    components: {
        baseLayout,
        appSidebarDetails,

        memberDetails,

        appLoader,
        appTable,
        appPagination,
        appAddingBanner,
        appSearchBox,

        appDialogMemberRemoval,
    },

    data() {
        return {
            members_list: [],

            filter: {
                word: '',
                previous: '',

                sort: {},
                /*
                    sort: {
                        field: 'FirstName',
                        order: 'asc'
                    },
                */
            },

            sort: [],
            /*
                sort: [
                    { field: 'FirstName',    title: 'Name'  },
                    { field: 'Email',        title: 'Email' },
                ],
            */

            cols: [
                { key: 'email', title: 'Username',               },
                { key: 'teams', title: 'Teams', type: 'tags', },

                { key: 'remove', action: 'remove-member', icon: 'remove', behavior: 'detached', },
            ],

            pagination: {
                page: 1,
                total: 0,
                limit: 10,
                range: 2,
            },

            member_uuid: null,
            auth_uuid: null,
            auth_spid: null,
            auth_id: null,
            auth_provider: null,

            scroll_locked: false,

            loading: false,

            dialogs: {
                member: {
                    remove: {
                        show: false,

                        uuid:   null,
                        email:  null,
                        authid: null,

                        AuthRoleGroups: [],
                    },
                },
            },
        }
    },

    methods: {
        init(to, from) {
            const getMembers = () => { this.getMembers(to.params.page) }

            if (from.name && /^member(-|$)/i.test(from.name)) {
                this.loadMembersFilter()
                    .then(getMembers)
                    .catch(getMembers)
            } else {
                this.$store.dispatch('clearMembersFilter')
                    .then(getMembers)
                    .catch(getMembers)
            }
        },

        loadMembersFilter() {
            return this.$store.dispatch('loadMembersFilter')
                .then(loaded_filter => {
                    let filter = {}

                    for (const key in this.filter) {
                        filter[key] = key in loaded_filter
                            ? typeof this.filter[key] == 'string'
                                ? loaded_filter[key]
                                : { ...this.filter[key], ...loaded_filter[key] }
                            : this.filter[key]
                    }

                    this.filter = filter
                })
                .catch(error => Promise.resolve(error))
        },

        getMembers(page, filter) {
            this.loading = true

            filter = {
                ...this.filter,
                ...filter,
            }

            let params = {
                "SPID": this.current_spid,

                'IncludeInactive': true,

                'SearchOptions.PageNumber': page ? page : this.pagination.page,
                'SearchOptions.PageSize':   this.pagination.limit,
            }

            const word = filter.word.trim()

            if (word.length > 0) {
                params = {
                    ...params,

                    'SmartSearch': word,

                    'SearchOptions.PageNumber': word !== filter.previous ? 1 : params['SearchOptions.PageNumber']
                }

                this.filter.word     = word
                this.filter.previous = word
            }

            this.$store.dispatch('getMembers', { params, filter })
                .then(response => {
                    this.members_list = response.AuthRoleGroupMembersWithGroups

                    const { DisplayPageList, PageNumber, TotalItemCount } = response.PageInfo

                    if (DisplayPageList) {
                        this.pagination.page = PageNumber
                        this.pagination.total = TotalItemCount || DisplayPageList.length * this.pagination.limit

                        if (this.$route.params.page != this.pagination.page
                            && !(
                                this.$route.params.page === undefined
                                && this.pagination.page === 1
                            )
                        ) {
                            this.$router.push({ name: this.$route.name, params: { page: this.pagination.page }, replace: true })
                        }
                    }

                    this.loading = false
                })
                .catch(error => {
                    this.$store.dispatch('addToast', {
                        message: errMessage(error),
                        type: 'error',
                        delay: 5000,
                    })

                    this.members_list = []

                    this.loading = false
                })
        },

        getMemeberTeamsTags(teams) {
            let tags = []

            for (let i = 0, len = teams.length; i < len; i++) {
                const team = teams[i]

                /*
                    if (team.UUID in this.teams) {
                        tags.push( this.teams[ team.UUID ].Name )
                    }
                */

                tags.push(team.Name)
            }

            return tags
        },

        fullname(firstname, lastname) {
            const fullname = [firstname, lastname].join(' ').trim()

            return fullname.length ? fullname : '?'
        },

        onSearch() {
            this.getMembers()
        },

        onSortChange(sort) {
            this.filter.sort = sort
            this.getMembers()
        },

        onPageChange(page) {
            this.getMembers(page)
        },

        openMemberDetails(member) {
            this.$router.push({ name: 'member', params: { auth_id: member.auth_id } })
        },

        onMembersRowClick(member) {
            if (this.member_uuid == member.uuid && this.auth_id == member.auth_id) {
                this.closeSidebar()
            } else {
                this.member_uuid = member.uuid
                this.auth_spid = member.auth_spid
                this.auth_uuid = member.auth_id ? member.auth_id : member.uuid
                this.auth_provider = member.auth_provider
                this.auth_id = member.auth_id

                this.openSidebar()
            }
        },

        openSidebar() {
            this.$emit('open-sidebar')
            this.$nextTick(this.toggleMemebersResize)

            if (document.body.clientWidth <= this.$mobile_size && !this.scroll_locked) {
                bodyScrollLock.disableBodyScroll(this.$el)
                this.scroll_locked = true
            }
        },

        closeSidebar() {
            this.member_uuid = null
            this.auth_id = null

            this.$emit('close-sidebar')
            this.$nextTick(this.toggleMemebersResize)

            if (this.scroll_locked) {
                bodyScrollLock.enableBodyScroll(this.$el)
                this.scroll_locked = false
            }
        },

        toggleMemebersResize() {
            this.$refs.members.watchingResize()
        },

        onRemoveMember(row) {
            this.dialogs.member.remove.show = true

            this.dialogs.member.remove.uuid  = row.UUID

            this.dialogs.member.remove.authid  = row.auth_id

            this.dialogs.member.remove.email = row.email

            this.dialogs.member.remove.AuthRoleGroups = row.AuthRoleGroups
        },

        onDialogMemberRemovalConfirm() {
            if (this.member_uuid == this.dialogs.member.remove.uuid) {
                this.closeSidebar()
            }

            this.getMembers()

            this.dialogs.member.remove.show = false

            this.dialogs.member.remove.uuid  = null
            this.dialogs.member.remove.email = null

            this.dialogs.member.remove.AuthRoleGroups = []
        },

        onDialogMemberRemovalClose() {
            this.dialogs.member.remove.show = false

            this.dialogs.member.remove.uuid  = null
            this.dialogs.member.remove.email = null

            this.dialogs.member.remove.AuthRoleGroups = []
        },
    },

    computed: {
        ...mapGetters([
            'teams',

            'current_spid',

            '$mobile_size',
        ]),

        members() {
            return this.members_list.map(member => ({
                uuid: member.AuthRoleGroupMember.UUID,
                auth_spid: member.AuthRoleGroupMember.SPID,
                auth_id: member.AuthRoleGroupMember.AuthID,
                auth_provider: member.AuthRoleGroupMember.IdentityProvider,

                UUID: member.AuthRoleGroupMember.UUID,

                email: member.AuthRoleGroupMember.DisplayName ? member.AuthRoleGroupMember.DisplayName : member.AuthRoleGroupMember.AuthID,
                teams: this.getMemeberTeamsTags(member.AuthRoleGroups),

                AuthRoleGroups: member.AuthRoleGroups,
            }))
        },
    },

    beforeRouteEnter(to, from, next) {
        next(route => { route.init( to, from ) })
    },

    beforeRouteLeave(to, from, next) {
        this.closeSidebar()
        next()
    },
}
</script>

<style lang="scss">
.page-members {
    h1 {
        &.heading {
            margin-bottom: 48px;
        }
    }

    .app-search-box {
        margin-bottom: 30px;
    }

    .members-table {
        @include table-cols-width((200px, 455px, 48px), true, 1);
    }

    .app-pagination {
        margin-top: 30px;
    }
}

@media (max-width: $tablet-size) {
    .page-members {
        .app-adding-banner {
            margin-top: 30px;
        }
    }
}

@media (max-width: $mobile-size) {
    .page-members {
        .app-search-box {
            margin-bottom: 15px;
        }

        .members-table {
            @include table-cols-width-mobile((90px, 160px, 24px), true);
        }

        .app-pagination {
            margin-top: 15px;
        }

        .app-adding-banner {
            margin-top: 15px;
        }
    }
}
</style>